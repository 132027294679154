import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Seo from "gatsby-plugin-wpgraphql-seo"
import BluePageContent from "../../components/StyledElements/bluePageContent"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import styled from "styled-components"
import MFsquaresWhiteBottom from "../../components/StyledElements/mfsquaresWhiteBottom"
import WhitePageContent from "../../components/StyledElements/whitePageContent"
import MFsquaresBlueTop from "../../components/StyledElements/mfsquaresBlueTop"

const WpPage = ({ data }) => {
  const { wpPage } = data

  return (
    <>
      <Seo post={wpPage} />
      <Layout>
        <BluePageContent>
          <h1>{wpPage.title}</h1>
          <TwoGrid>
            <div>
              <Heading>{wpPage.ACFSEOpage.heroseopage.herosubhead}</Heading>
              <Text>{parse(`${wpPage.ACFSEOpage.heroseopage.herotext}`)}</Text>
            </div>
            <div>
              <GatsbyImage
                image={
                  wpPage.ACFSEOpage.heroseopage.heroimg.localFile
                    .childImageSharp.gatsbyImageData
                }
                alt={wpPage.ACFSEOpage.heroseopage.heroimg.altText}
              />
            </div>
          </TwoGrid>
          <MFsquaresWhiteBottom />
        </BluePageContent>
        <WhitePageContent>
          <MFsquaresBlueTop />
          {parse(`${wpPage.ACFSEOpage.seotext}`)}
        </WhitePageContent>
      </Layout>
    </>
  )
}

export default WpPage

export const query = graphql`
  query SEOPageById($id: String) {
    wpPage(id: { eq: $id }) {
      __typename
      id
      uri
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACFSEOpage {
        seotext
        heroseopage {
          herosubhead
          herotext
          heroimg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

const TwoGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    grid-column-gap: 8rem;
    padding: 50px 0px;
    position: relative;
  }
`

const Text = styled.div`
  font-weight: 300;
  color: #eff1ef;
  & p {
    max-width: 600px;
    font-size: 18px;
    font-weight: 300;
    padding-left: 0 !important;
  }
`

const Heading = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #eff1ef;
  max-width: 600px;
  margin-top: 0px !important;
`
