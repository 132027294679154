import React from "react"
import MFsquaresWhiteIcon from "../../images/MFsquaresWhite.svg"
import styled from "styled-components"

const MFsquaresWhiteBottom = () => {
  return (
    <MFSquaresWrapper>
      <MFSquaresBottom
        src={MFsquaresWhiteIcon}
        alt="Marketingfabrikken squares"
      />
    </MFSquaresWrapper>
  )
}

export default MFsquaresWhiteBottom

const MFSquaresBottom = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
`

const MFSquaresWrapper = styled.div`
  @media (max-width: 1280px) {
    padding-top: 100px;
    margin-top: 100px;
  }
`
